import React, { FC } from 'react';
import styled from 'styled-components';
import BodyText from '../atoms/BodyText';

interface Props {
    variant?: 'large' | 'medium';
    reference?: { jobTitle?: string; person?: string };
}
const Quote: FC<Props> = ({ children, reference, variant }) => {
    return (
        <Container>
            <StyledQuote variant={variant === 'large' ? 'quoteHuge' : 'huge'}>{children}</StyledQuote>
            {reference && (
                <BodyText variant={'small'}>
                    {reference.person && (
                        <>
                            <strong>{reference.person}</strong>
                            <br />
                        </>
                    )}
                    {reference.jobTitle}
                </BodyText>
            )}
        </Container>
    );
};

export default Quote;

const Container = styled.blockquote`
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
`;
export const StyledQuote = styled(BodyText)`
    quotes: '“' '”';
    position: relative;
    ::before {
        margin-left: -0.5em;
        content: open-quote;
    }
    ::after {
        content: close-quote;
    }
    margin-bottom: 3rem;
`;
