import { graphql } from 'gatsby';
import React, { FC } from 'react';
import styled from 'styled-components';
import { itemWithNumber } from '../../constants/css/itemWithNumber';
import theme from '../../constants/theme';
import { SellingPointsFragment } from '../../entities/operationResults';
import Grid from '../atoms/Grid';
import Title from '../atoms/Title';
import Cell from './Cell';

export const sellingPointsFragment = graphql`
    fragment SellingPointsFragment on Craft_SellingPointsSuperTableBlockType {
        heading
        items {
            text
        }
    }
`;
interface Props {
    data: SellingPointsFragment;
    variant: 'vacancies' | 'vacancy';
}

const SellingPoints: FC<Props> = ({ data: { heading, items }, variant }) => {
    return (
        <Grid marginRight={2}>
            <Cell centerChildren horizontal={{ start: 4, end: { xl: 12, l: 14, m: 15, s: 15 } }}>
                <Title variant={'huge'} as={'h3'}>
                    {heading}
                </Title>
            </Cell>
            <Cell
                centerChildren
                horizontal={{
                    start: { xl: 13, l: 15, m: 16, s: 16 },
                    end: variant === 'vacancy' ? { xl: 24, l: 24, m: 28, s: 32 } : { xl: 19, l: 21, m: 24, s: 24 },
                }}
            >
                {items &&
                    items.map((item, i) => (
                        <SellingPoint kind={variant} key={i} as={'h4'}>
                            {item && item.text}
                        </SellingPoint>
                    ))}
            </Cell>
        </Grid>
    );
};

export default SellingPoints;

const SellingPoint = styled(Title).attrs({ variant: 'medium' })<{ kind: 'vacancies' | 'vacancy' }>`
    &:first-child {
        padding-top: 2.6rem;
    }
    font-weight: 500;
    white-space: pre-line;
    letter-spacing: -0.02em;
    font-size: 2.4rem;
    line-height: 1.2;
    counter-increment: point;
    ${({ kind }) => itemWithNumber('point', { vacancies: theme.colors.gorse, vacancy: theme.colors.black }[kind])};

    @media screen and (min-height: ${theme.mediaQueries.height.m}) {
        font-size: 3rem;
    }
    @media screen and (min-height: ${theme.mediaQueries.width.s}) {
        font-size: 2.6rem;
    }
    @media screen and (min-height: ${theme.mediaQueries.height.l}) {
        &:first-child {
            padding-top: 5rem;
        }
        font-size: 3.6rem;
    }
    @media screen and (min-height: ${theme.mediaQueries.height.xl}) {
        font-size: 4.4rem;
    }
    @media (max-width: ${theme.mediaQueries.width.m}), (max-height: ${theme.mediaQueries.height.m}) {
        font-size: 3rem;
    }
    @media (max-width: ${theme.mediaQueries.width.s}), (max-height: ${theme.mediaQueries.height.s}) {
        font-size: 2.5rem;
    }
    @media (${theme.mediaQueries.vertical}) {
        margin-left: 4rem;
    }
`;
