import React, { FC, HTMLAttributes } from 'react';
import styled from 'styled-components';
import { hideOnVertical } from '../../constants/css/hideOnVertical';
import theme from '../../constants/theme';
import { ResolutionSpec } from '../../entities/ResolutionSpec';
import { numberToResolutionSpec } from '../../utils/numberToResolutionColumns';

interface Position {
    start: number;
    end: number;
}

interface ResolutionPosition {
    start: ResolutionSpec;
    end: ResolutionSpec;
}

interface Props extends HTMLAttributes<HTMLDivElement> {
    horizontal?: Position | { start: ResolutionSpec | number; end: ResolutionSpec | number };
    vertical?: Position | { start: ResolutionSpec | number; end: ResolutionSpec | number };
    centerChildren?: boolean;
    displayOnVertical?: boolean;
}
/**
 * The screen consists of 30 rows and 20 columns.
 * When defining a Cell, take into account a safe zone of 3 rows for the navigation menu
 *
 */
const Cell: FC<Props> = ({ children, horizontal, vertical, ...rest }) => {
    const computedHorizontal = horizontal && {
        start: numberToResolutionSpec(horizontal.start),
        end: numberToResolutionSpec(horizontal.end),
    };
    const computedVertical = vertical && {
        start: numberToResolutionSpec(vertical.start),
        end: numberToResolutionSpec(vertical.end),
    };

    return (
        <Container horizontal={computedHorizontal} vertical={computedVertical} {...rest}>
            {children}
        </Container>
    );
};

export default Cell;

interface CellProps extends Props {
    horizontal?: ResolutionPosition;
    vertical?: ResolutionPosition;
}

const computeSpec = (
    variant: 's' | 'm' | 'l' | 'xl',
    options: { position?: ResolutionPosition; direction: 'column' | 'row' }
) => {
    const { direction, position } = options;
    const gridDirection = `grid-${direction}: `;
    let specification = direction === 'column' ? '1 / -1' : '4 / 26'; // defaults
    if (position) {
        specification = `${position.start[variant]} / ${position.end[variant]}`;
    }
    return `${gridDirection}${specification};`;
};

const Container = styled.div<CellProps>`
    ${({ displayOnVertical = true }) => !displayOnVertical && hideOnVertical};
    @media (${theme.mediaQueries.horizontal}) {
        ${({ centerChildren }) => (centerChildren ? 'align-self: center;' : '')}
        ${({ horizontal }) => computeSpec('s', { position: horizontal, direction: 'column' })}
        ${({ vertical }) => computeSpec('s', { position: vertical, direction: 'row' })}

    }

    @media (${theme.mediaQueries.horizontal}) and (min-height: ${theme.mediaQueries.height.m}) {

        ${({ horizontal }) => computeSpec('m', { position: horizontal, direction: 'column' })}
        ${({ vertical }) => computeSpec('m', { position: vertical, direction: 'row' })}

    }

    @media (min-width: ${theme.mediaQueries.width.l}) and (min-height: ${theme.mediaQueries.height.l}) {
        ${({ horizontal }) => computeSpec('l', { position: horizontal, direction: 'column' })}
        ${({ vertical }) => computeSpec('l', { position: vertical, direction: 'row' })}
    }
    @media (min-width: ${theme.mediaQueries.width.xl}) and (min-height: ${theme.mediaQueries.height.xl}) {
        ${({ horizontal }) => computeSpec('xl', { position: horizontal, direction: 'column' })}
        ${({ vertical }) => computeSpec('xl', { position: vertical, direction: 'row' })}

    }
`;
