import React, { FC } from 'react';
import styled from 'styled-components';
import { fullWidthOnVertical } from '../../constants/css/fullWidthOnVertical';
import theme from '../../constants/theme';
import { CommonImageFragment } from '../../entities/operationResults';
import Grid from '../atoms/Grid';
import Img from '../atoms/Img';

interface Props {
    image?: CommonImageFragment | null;
    alignImage?: 'bottom' | 'top';
    leftMargin?: number;
    rightMargin?: number;
    alignChildren?: 'bottom' | 'top';
}

const ContentSection: FC<Props> = ({ children, alignImage, image, alignChildren, rightMargin = 3 }) => (
    <Grid marginRight={rightMargin}>
        <ContentCell alignImage={image && alignImage ? alignImage : undefined} alignChildren={alignChildren}>
            <Description>{children}</Description>
        </ContentCell>
        {image && image.url800 && (
            <ImageCell alignImage={alignImage || 'bottom'}>
                <Image
                    alignImage={alignImage}
                    ratio={parseInt(image.height || '0', 10) / parseInt(image.width || '0', 10)}
                    srcSet={`
                        ${image.url300} 500w,
                        ${image.url500} 1000w,
                        ${image.url800} 1500w,
                        ${image.url1500} 2500w
                    `}
                    src={image.url800}
                    alt={image.title || undefined}
                />
            </ImageCell>
        )}
    </Grid>
);

export default ContentSection;
const ContentCell = styled.div<{ alignImage?: 'bottom' | 'top'; alignChildren?: 'bottom' | 'top' }>`
    @media (${theme.mediaQueries.vertical}) {
        display: flex;
        flex-direction: column;
        margin-bottom: 4rem;
    }

    @media (${theme.mediaQueries.horizontal}) {
        max-width: 88rem;
        display: flex;
        grid-column: 1 / 10;
        ${({ alignImage }) =>
            alignImage === 'bottom'
                ? `
            grid-row: 4 / 13;
            align-items: flex-end;
        `
                : alignImage === 'top'
                ? `
            grid-row: 23 / -1;
            align-items: flex-start;
        `
                : `
            grid-row: 4 / -4;
            align-items: center;
        `}
    }
    @media (max-width: ${theme.mediaQueries.width.xxl}), (max-height: ${theme.mediaQueries.height.xxl}) {
        grid-row: ${({ alignImage }) =>
            alignImage === 'bottom'
                ? `
            4 / 15;
        `
                : alignImage === 'top'
                ? `
            22 / -1;
        `
                : null};
    }
    @media (max-width: ${theme.mediaQueries.width.xl}), (max-height: ${theme.mediaQueries.height.xl}) {
        ${({ alignImage }) =>
            alignImage === 'bottom'
                ? `
            grid-row: 4 / 14;
        `
                : alignImage === 'top'
                ? `
            grid-row: 20 / -1;
        `
                : null}
    }
    @media (max-width: ${theme.mediaQueries.width.l}), (max-height: ${theme.mediaQueries.height.l}) {
        ${({ alignImage }) =>
            alignImage === 'bottom'
                ? `
            grid-row: 4 / 14;
        `
                : alignImage === 'top'
                ? `
            grid-row: 20 / -1;
        `
                : null}
    }
    @media (max-width: ${theme.mediaQueries.width.m}), (max-height: ${theme.mediaQueries.height.m}) {
        grid-column: 1 / 8;
        grid-row: 5 / -5;
        align-items: center;
    }
`;

const ImageCell = styled.div<{ alignImage: 'bottom' | 'top' }>`
    grid-column: 1 / 12;

    @media (${theme.mediaQueries.horizontal}) {
        ${({ alignImage }) =>
            alignImage === 'bottom'
                ? `
            grid-row: -18 / -1;
        `
                : `
            grid-row: 4 / 22;
        `}
    }
    @media (max-width: ${theme.mediaQueries.width.xxl}), (max-height: ${theme.mediaQueries.height.xxl}) {
        ${({ alignImage }) =>
            alignImage === 'bottom'
                ? `
            grid-row: -17 / -1;
        `
                : `
            grid-row: 4 / 19;
            height: 100%;
        `}
    }
    @media (max-width: ${theme.mediaQueries.width.l}), (max-height: ${theme.mediaQueries.height.l}) {
        grid-column: 1 / 10;
        ${({ alignImage }) =>
            alignImage === 'bottom'
                ? `
            grid-row: -17 / -1;
        `
                : `
            align-self: flex-end;
            grid-row: 4 / 18;
        `}
    }
    @media (max-width: ${theme.mediaQueries.width.m}), (max-height: ${theme.mediaQueries.height.m}) {
        grid-column: 9 / 18;
        grid-row: 5 / -5;
        align-self: center;
        height: unset;
    }
    @media (${theme.mediaQueries.vertical}) {
        display: flex;
        flex-direction: column;
        align-self: unset;
    }
`;

const Description = styled.div`
    max-width: 80rem;
`;

const Image = styled(Img)<{ alignImage?: 'bottom' | 'top' }>`
    ${fullWidthOnVertical};
    height: 100%;
    max-height: 70vh;
    picture > img {
        max-height: 100%;
        object-fit: contain;
        object-position: bottom left;
        @media (max-width: ${theme.mediaQueries.width.l}), (max-height: ${theme.mediaQueries.height.l}) {
            object-position: ${({ alignImage }) => `left ` + (alignImage === 'top' ? 'bottom' : 'bottom')};
        }
        @media (max-width: ${theme.mediaQueries.width.m}), (max-height: ${theme.mediaQueries.height.m}) {
            object-position: center;
        }
    }
`;
