import styled from 'styled-components';
import theme from '../../constants/theme';

export const FixedGrid = styled.div<{ isVertical?: boolean }>`
    ${({ isVertical }) =>
        isVertical
            ? `
        @media screen and (${theme.mediaQueries.horizontal}) {
            padding: 10rem 0;
        }
        display: block;
    `
            : `
        display: grid;
        grid-auto-columns: 16rem;
        grid-template-rows: repeat(30, 1fr);

        @media (max-width: ${theme.mediaQueries.width.xxl}), (max-height: ${theme.mediaQueries.height.xxl}) {
            grid-auto-columns: 12rem;
        }
        @media (max-width: ${theme.mediaQueries.width.xl}), (max-height: ${theme.mediaQueries.height.xl}) {
            grid-auto-columns: 11rem;
        }
        @media (max-width: ${theme.mediaQueries.width.l}), (max-height: ${theme.mediaQueries.height.l}) {
            grid-auto-columns: 10rem;
        }
        @media (max-width: ${theme.mediaQueries.width.m}), (max-height: ${theme.mediaQueries.height.m}) {
            grid-auto-columns: 6.4rem;
        }
    `}

    @media screen and (${theme.mediaQueries.vertical}) {
        display: block;
    }
`;
