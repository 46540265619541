import { css } from 'styled-components';
import { tinyCss } from '../../components/atoms/Title';
import theme from '../theme';

export const itemWithNumber = (counter: string, color?: string) => css`
    position: relative;
    &:before {
        position: absolute;
        left: -3em;
        content: counter(${counter}, decimal-leading-zero);
        margin-right: 1.8rem;
        font-weight: normal;
        color: ${color ? color : 'inherit'};
        margin-top: 0.3em;
        @media (${theme.mediaQueries.horizontal}) and (min-height: ${theme.mediaQueries.height.m}) {
            margin-top: 0.6em;
        }
        @media (${theme.mediaQueries.horizontal}) and (min-height: ${theme.mediaQueries.height.l}) {
            margin-top: 1em;
        }
        ${tinyCss}
    }
`;
