import { graphql } from 'gatsby';
import React, { FC, Fragment } from 'react';
import { SelfDevelopmentFragment } from '../../entities/operationResults';
import BodyText from '../atoms/BodyText';
import Title from '../atoms/Title';
import ContentSection from '../molecules/ContentSection';

export const selfDevelopmentFragment = graphql`
    fragment SelfDevelopmentFragment on Craft_SelfDevContentBlock {
        blockTitle
        description
        image {
            ...CommonImageFragment
        }
    }
`;

interface Props {
    data: SelfDevelopmentFragment[];
}
const SelfDevelopment: FC<Props> = ({ data }) => (
    <Fragment>
        {data &&
            data.map((block, i) => {
                return block ? (
                    <ContentSection
                        key={i}
                        image={block.image && block.image[0]}
                        alignImage={i % 2 === 0 ? 'top' : 'bottom'}
                    >
                        <Title>{block.blockTitle}</Title>
                        <BodyText>{block.description}</BodyText>
                    </ContentSection>
                ) : null;
            })}
    </Fragment>
);

export default SelfDevelopment;
