import { graphql, Link } from 'gatsby';
import React, { FC, useContext } from 'react';
import styled from 'styled-components';
import theme from '../../constants/theme';
import {
    SolutionsOverviewFragment,
    VacancyListFragment,
    VacancyListItemFragment,
} from '../../entities/operationResults';
import ArrowButton from '../atoms/ArrowButton';
import BodyText from '../atoms/BodyText';
import Grid from '../atoms/Grid';
import Title from '../atoms/Title';
import Cell from './Cell';
import CmsContent from '../atoms/CmsContent';
import { ExternalContentLink } from '../atoms/ContentLink';
import { SiteContext } from '../../contexts/SiteContext';

interface Props {
    header?: string | null;
    solutions?: SolutionsOverviewFragment[];
    vacancyList?: VacancyListFragment;
    vacancies?: VacancyListItemFragment[];
}

export const vacancyListFragment = graphql`
    fragment VacancyListItemFragment on Craft_Vacancy {
        title
        uri
    }

    fragment VacancyListFragment on Craft_VacancyListSuperTableBlockType {
        heading
        description {
            content
        }
        faqLink {
            url
            customText
            target
        }
    }
`;

const VacancyList: FC<Props> = ({ header, solutions, vacancyList, vacancies }) => {
    const site = useContext(SiteContext);
    // TODO: If redesigned, solution information needs to be separated
    const heading = vacancyList && vacancyList.heading;
    const description = vacancyList && vacancyList.description;
    const faqLink = vacancyList && vacancyList.faqLink;
    const link = faqLink && faqLink.url;
    const linkText = faqLink && faqLink.customText;
    const linkTarget = faqLink && faqLink.target;

    return (
        <Grid marginRight={2} id="vacatures">
            <Cell centerChildren horizontal={{ start: 3, end: { xl: 9, l: 11, m: 11, s: 11 } }}>
                <Heading isHeader={!!header}>{heading ? heading : header && header}</Heading>
                {description && description.content && <CmsContent html={description.content} />}
                <List>
                    {vacancies &&
                        vacancies.map((vacancy, i) =>
                            vacancy ? (
                                <Item key={i}>
                                    <StyledLink to={site ? `/${site}/${vacancy.uri}/` : `/${vacancy.uri}/`}>
                                        <BodyText>{vacancy.title}</BodyText>
                                        <ArrowButton variant={'light'} />
                                    </StyledLink>
                                </Item>
                            ) : null
                        )}
                    {solutions &&
                        solutions.map((solution, i) =>
                            solution ? (
                                <Item key={i}>
                                    <StyledLink to={site ? `/${site}/${solution.uri}/` : `/${solution.uri}/`}>
                                        <BodyText>{solution.title}</BodyText>
                                        <ArrowButton variant={'light'} />
                                    </StyledLink>
                                </Item>
                            ) : null
                        )}
                </List>
                {link && linkText && linkTarget && (
                    <RecruiterLink href={link} color={theme.colors.gorse} target={linkTarget}>
                        {linkText}
                    </RecruiterLink>
                )}
            </Cell>
        </Grid>
    );
};

export default VacancyList;

const Heading = styled(Title)<{ isHeader?: boolean }>`
    ${({ isHeader }) => isHeader && `padding-bottom: 5rem;`}
`;

const StyledLink = styled(Link)`
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-decoration: none;
    color: inherit;
`;
const Item = styled.li`
    border-bottom: ${theme.colors.alto} 1px solid;
    :first-of-type {
        border-top: ${theme.colors.alto} 1px solid;
    }
`;
const List = styled.ul`
    list-style: none;
    padding: 0 0 3rem 0;
    @media (${theme.mediaQueries.horizontal}) {
        max-height: 52rem;
        overflow: hidden;
        overflow-y: auto;
    }
`;
const RecruiterLink = styled(ExternalContentLink)`
    margin-top: 2rem;
`;
