import React, { FC } from 'react';
import styled from 'styled-components';
import theme from '../../constants/theme';
import { TeamMemberPhotoFragment } from '../../entities/operationResults';
import { getRatio } from '../../utils/getRatio';
import Circle from '../atoms/Circle';
import Grid from '../atoms/Grid';
import Img from '../atoms/Img';
import Cell from './Cell';

interface Props {
    photo: TeamMemberPhotoFragment | null;
    circleColor?: string;
    variant?: 'quote' | 'question';
}
const TeamMember: FC<Props> = ({ children, photo, circleColor = theme.colors.athensGray, variant = 'quote' }) => {
    return photo ? (
        <Grid marginRight={2}>
            <ImageCell>
                {photo && (
                    <Img
                        style={{ zIndex: 1 }}
                        src={photo.url700 || undefined}
                        srcSet={`${photo.url300} 800w, ${photo.url500} 1440w, ${photo.url700} 2000w`}
                        ratio={getRatio(photo.height, photo.width) || 840 / 362}
                        alt={photo.title || undefined}
                    />
                )}
                <TeamMemberCircle diameter={50} fill={circleColor} />
            </ImageCell>
            <Cell
                style={{ zIndex: 1 }}
                horizontal={{
                    start: variant === 'question' ? { xl: 8, l: 8, m: 9, s: 10 } : { xl: 8, l: 8, m: 8, s: 10 },
                    end: variant === 'question' ? { xl: 13, l: 15, m: 19, s: 16 } : { xl: 15, l: 15, m: 19, s: 19 },
                }}
                centerChildren
            >
                {children}
            </Cell>
        </Grid>
    ) : null;
};

export default TeamMember;

const ImageCell = styled.div`
    place-items: center;
    grid-row: 7 / 25;
    position: relative;
    grid-column: 4 / 11;

    @media screen and (min-height: ${theme.mediaQueries.height.m}) and (min-width: ${theme.mediaQueries.width.m}) {
        grid-column: 4 / 9;
    }
    > figure {
        max-height: 70vh;

        img {
            object-fit: contain;
            object-position: center center;
        }
    }
`;

const TeamMemberCircle = styled(Circle)`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    z-index: 0;
    max-width: 100vw;
`;
