import { graphql } from 'gatsby';
import React, { FC, Fragment, ReactElement } from 'react';
import styled, { css } from 'styled-components';
import theme from '../../constants/theme';
import { HeroFragment } from '../../entities/operationResults';
import BodyText from '../atoms/BodyText';
import { FixedGrid } from '../atoms/FixedGrid';
import Title from '../atoms/Title';
import CmsContent from '../atoms/CmsContent';
import { ContentStyle } from '../atoms/ContentLink';

export const fragment = graphql`
    fragment HeroFragment on Craft_HeroSuperTableBlockType {
        introTitle
        description {
            content
        }
        illustration {
            url
        }
    }
`;

type HeroVariant = 'home' | 'default';

interface Props {
    data: HeroFragment;
    ratio?: number;
    variant?: HeroVariant;
    copyChildren?: ReactElement;
}

const Hero: FC<Props> = ({
    data: { introTitle, description, illustration },
    ratio,
    copyChildren = null,
    variant = 'default',
    children = null,
}) => {
    return (
        <Fragment>
            <RoundedGrid textColor="#000" backgroundColor="#fff">
                <CopyCell variant={variant}>
                    <Title variant="huge">{introTitle}</Title>
                    {copyChildren}
                    {description && description.content && (
                        <CmsContent html={description.content} paragraphWrapper={HeroBodyText} />
                    )}
                </CopyCell>
            </RoundedGrid>
            {illustration?.[0]?.url ? (
                <ImgContainer ratio={ratio || 0}>
                    <StyledImg src={illustration[0].url} alt={introTitle || undefined} ratio={ratio || 0} />
                </ImgContainer>
            ) : (
                children
            )}
        </Fragment>
    );
};

const StyledImg = styled.img<{ ratio: number }>`
    transform: translateZ(0);
    position: absolute;
    top: 0;
    left: 0;
    max-height: 100%;
    height: 100%;
`;

const ImgContainer = styled.div<{ ratio: number }>`
    position: relative;
    width: ${({ ratio }) => 72 / ratio + 'vh'};
    margin: 16vh 4vh 12vh 4vh;
    padding-top: 0 !important;

    &:before {
        display: block;
        content: '';
        width: 100%;
        padding-top: ${({ ratio }) => Math.round(ratio * 100)}%;
    }

    @media (${theme.mediaQueries.vertical}) {
        width: 100%;
        margin: 0;
    }
`;

const HeroBodyText = styled(BodyText)`
    @media (${theme.mediaQueries.horizontal}) {
        max-width: 80rem;
        padding: 0;
        margin: 4rem 0 0;
    }

    a {
        ${ContentStyle}
        color: ${theme.colors.royalBlue};
        border-bottom: 2px currentColor solid;
    }
`;

const RoundedGrid = styled(FixedGrid)<{ backgroundColor: string | null; textColor: string | null }>`
    background-color: ${({ backgroundColor }) => backgroundColor || 'transparent'};
    color: ${({ textColor }) => textColor};
    position: relative;

    ${({ backgroundColor }) =>
        backgroundColor &&
        css`
            @media screen and (${theme.mediaQueries.vertical}) {
                margin: -100px -25px 40px -25px;
                padding: 100px 25px 20px;
            }
        `}
`;

const CopyCell = styled.div<{ variant?: HeroVariant }>`
    grid-row: 1 / -1;
    align-self: center;

    ${({ variant }) =>
        variant === 'home'
            ? `
        grid-column: 4 / 12;
        margin-right: -12rem;
    `
            : `
        grid-column: 4 / 11;
    `}

    @media screen and (${theme.mediaQueries.vertical}) {
        ${({ variant }) =>
            variant === 'home'
                ? `
            grid-column: 1 / 5;
            margin-right: -2rem;
        `
                : `
            grid-column: 1 / 6;
            position: relative;
        `}
    }
`;

export default Hero;
