import { graphql } from 'gatsby';
import React, { FC, Fragment } from 'react';
import { ImageShowcaseFragment } from '../../entities/operationResults';
import BodyText from '../atoms/BodyText';
import Title from '../atoms/Title';
import ContentSection from '../molecules/ContentSection';

export const imageShowcaseFragment = graphql`
    fragment ImageShowcaseFragment on Craft_ImageShowcaseSuperTableBlockType {
        heading
        description
        tiles {
            ... on Craft_TilesImageTile {
                caption
                captionHeading
                image {
                    ...CommonImageFragment
                }
            }
        }
    }
`;

interface Props {
    data: ImageShowcaseFragment;
}
const ImageShowcase: FC<Props> = ({ data }) => (
    <Fragment>
        <ContentSection>
            <Title variant={'large'}>{data.heading}</Title>
            <BodyText>{data.description}</BodyText>
        </ContentSection>
        {data.tiles &&
            data.tiles.map((tile, i) =>
                tile ? (
                    <ContentSection
                        key={i}
                        alignImage={i % 2 === 0 ? 'bottom' : 'top'}
                        image={tile.image && tile.image[0]}
                    >
                        <Title>{tile.captionHeading}</Title>
                        <BodyText>{tile.caption}</BodyText>
                    </ContentSection>
                ) : null
            )}
    </Fragment>
);

export default ImageShowcase;
