import { graphql } from 'gatsby';
import React, { FC, Fragment, useContext } from 'react';
import theme from '../constants/theme';
import {
    CTAFragment,
    SelfDevelopmentFragment,
    VacancyListItemFragment,
    WorkingAtPageFragment,
    WorkingAtPageQuery,
} from '../entities/operationResults';
import withDefaultTransition from '../hocs/withDefaultTransition';
import BodyText from './atoms/BodyText';
import ContentLink from './atoms/ContentLink';
import { Section } from './atoms/Section';
import Title from './atoms/Title';
import ContentSection from './molecules/ContentSection';
import CTA from './molecules/CTA';
import SellingPoints from './molecules/SellingPoints';
import VacancyList from './molecules/VacancyList';
import Footer from './organisms/Footer';
import Hero from './organisms/Hero';
import ImageShowcase from './organisms/ImageShowcase';
import SelfDevelopment from './organisms/SelfDevelopment';
import TeamMemberQuote from './organisms/TeamMemberQuote';
import Meta from './atoms/Meta';
import { removeTags } from '../utils/removeTags';
import { SiteContext } from '../contexts/SiteContext';

interface Props {
    data: WorkingAtPageQuery;
}
export const query = graphql`
    query WorkingAtPageQuery($site: String, $uri: String) {
        craft {
            entry(site: $site, uri: $uri) {
                ...WorkingAtPageFragment
            }
            entries(site: $site, type: Vacancy) {
                ...VacancyListItemFragment
            }
        }
    }
`;

export const fragment = graphql`
    fragment WorkingAtPageFragment on Craft_WorkingAt {
        id
        title
        hero {
            ...HeroFragment
        }
        sellingPoints {
            ...SellingPointsFragment
        }
        selfDev {
            ...SelfDevelopmentFragment
        }
        frontrunner {
            frontrunnerTitle
            description
            uriLink {
                text
                entry {
                    uri
                }
            }
            image {
                ...CommonImageFragment
            }
        }
        imageShowcase {
            ...ImageShowcaseFragment
        }
        teamMemberQuote {
            ...TeamMemberQuoteFragment
        }
        vacancyList {
            ...VacancyListFragment
        }
        callToAction {
            ...CTAFragment
        }
        directContact {
            ...DirectContactFragment
        }
        seo {
            ...MetaFragment
        }
    }
`;
const WorkingAt: FC<Props> = ({ data }) => {
    const site = useContext(SiteContext);
    const entry = data.craft.entry as WorkingAtPageFragment;

    const hero = entry.hero && entry.hero[0];
    const imageShowcase = entry.imageShowcase && entry.imageShowcase[0];
    const teamMemberQuote = entry.teamMemberQuote && entry.teamMemberQuote[0];
    const footer = entry.directContact && entry.directContact[0];
    const sellingPoints = entry.sellingPoints && entry.sellingPoints[0];
    const vacancyList = entry.vacancyList && entry.vacancyList[0];
    const vacancies = data.craft.entries as VacancyListItemFragment[];
    const frontRunner = entry.frontrunner && entry.frontrunner[0];
    const frontRunnerLink = frontRunner && frontRunner.uriLink;
    const ctaList = (entry.callToAction || []) as CTAFragment[];
    const meta = entry.seo && entry.seo[0];

    const { selfDev } = entry;
    return (
        <Fragment>
            <Meta
                title={meta && meta.seoTitle ? meta.seoTitle : entry.title}
                description={
                    meta && meta.seoDescription
                        ? meta.seoDescription
                        : hero && hero.description && removeTags(hero.description.content)
                }
                image={meta && meta.seoImage ? meta.seoImage : null}
            />
            <Section>{hero && <Hero data={hero} ratio={1369 / 2237} />}</Section>
            <Section background={theme.colors.shark} fontColor={theme.colors.white}>
                {sellingPoints && <SellingPoints variant={'vacancies'} data={sellingPoints} />}
            </Section>
            <Section paddingLeft={4} leadingCTA={true} endingCTA>
                {ctaList[0] && <CTA sourceId={entry.id} data={ctaList[0]} />}
                {selfDev && <SelfDevelopment data={selfDev as SelfDevelopmentFragment[]} />}
                {frontRunner && (
                    <ContentSection alignChildren={'top'}>
                        <Title>{frontRunner.frontrunnerTitle}</Title>
                        <BodyText>{frontRunner.description}</BodyText>
                        {frontRunnerLink && frontRunnerLink.entry && frontRunnerLink.entry.uri && (
                            <ContentLink
                                to={site ? `/${site}/${frontRunnerLink.entry.uri}/` : `/${frontRunnerLink.entry.uri}/`}
                            >
                                {frontRunnerLink.text}
                            </ContentLink>
                        )}
                    </ContentSection>
                )}
                {ctaList[1] && <CTA sourceId={entry.id} align={'right'} data={ctaList[1]} withMargin />}
            </Section>
            <Section paddingLeft={4} background={theme.colors.athensGray}>
                {imageShowcase && <ImageShowcase data={imageShowcase} />}
                {teamMemberQuote && <TeamMemberQuote circleColor={theme.colors.white} data={teamMemberQuote} />}
            </Section>
            <Section background={theme.colors.shark} fontColor={theme.colors.white} elevate>
                {vacancyList && vacancies && <VacancyList vacancies={vacancies} vacancyList={vacancyList} />}
                {ctaList[2] && <CTA sourceId={entry.id} data={ctaList[2]} withMargin align="right" />}
            </Section>
            <Section variant={'footer'}>{footer && <Footer entryId={entry.id} data={footer} hasFlatTop />}</Section>
        </Fragment>
    );
};

export default withDefaultTransition(WorkingAt);
