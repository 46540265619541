import { graphql } from 'gatsby';
import React, { FC } from 'react';
import { TeamMemberQuoteFragment } from '../../entities/operationResults';
import Quote from '../molecules/Quote';
import TeamMember from '../molecules/TeamMember';

export const teamMemberQuoteFragment = graphql`
    fragment TeamMemberQuoteFragment on Craft_TeamMemberQuoteSuperTableBlockType {
        quote
        fullName
        functionTitle
        photo {
            ...TeamMemberPhotoFragment
        }
    }
`;

interface Props {
    data: TeamMemberQuoteFragment;
    circleColor?: string;
}
const TeamMemberQuote: FC<Props> = ({ data: { fullName, functionTitle, photo, quote }, circleColor }) => {
    const teamMemberPhoto = photo && photo[0];
    return teamMemberPhoto ? (
        <TeamMember circleColor={circleColor} photo={teamMemberPhoto}>
            {fullName && functionTitle && (
                <Quote reference={{ jobTitle: functionTitle, person: fullName }} variant={'medium'}>
                    {quote}
                </Quote>
            )}
        </TeamMember>
    ) : null;
};

export default TeamMemberQuote;
